import { UserAgent } from '../Util/Browser.js';
import { LoadScript } from '../performance/LoadContent.js';

/**
 * Don't load Google Analytics if we're being tested by Google PageSpeed Insights
 * because they penalize sites for their own Google Analytics script!
 */
if (!UserAgent.fromWindow().isGooglePageSpeed()) {
  LoadScript('https://www.google-analytics.com/analytics.js');
}
